@import 'search.scss';
@import 'mixins.scss';
@import '../../node_modules/swiper/swiper-bundle.css';

@include secondary-navbar;

.search-form {
    max-width: 50rem;
    .button {
        padding: 7px 12px;
        height: auto;
        min-height: 0;
        font-size: 1.2rem;

    }
    @media (min-width: 767px) {
        .button {
            margin-top: -1px;
            border-radius: 0 15px 15px 0;
        }
    }
    @media (max-width: 767px) {
        .button {
            width: 100%;
        }
    }
}

.hero {
    .hero-body {
        background-position: top center;
        .content {
            .title {
                font-size: 2.2rem;
                line-height: 1.2;
                max-width: 40rem;
            }
        }
    }
    select.input {
        border-radius: 0;
    }
}

h4 {
    display: block;
    text-transform: uppercase;
    position: relative;
    font-size: 1.8rem;
    border-radius: 0.3125rem;
    text-align: center;
    font-weight: bold;
}
.swiper.categories {
    position: relative;
    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 5.625rem;
        background: transparent;
        background: $gradient-eol-rtl;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }
    // .links-wrapper {
    //     display: flex;
    //     flex-direction: row;
    //     overflow: hidden;
    // }
    padding: 1.25rem 0 1.25rem 0;
    .swiper-slide {
        width: auto;
    }
    .link {
        background: #FFF;
        border: 0.125rem solid $color-gray-darker;
        padding: 0.1875rem 1.25rem;
        text-decoration: none;
        border-radius: 0.75rem;
        color: $color-gray-darker;
        white-space: nowrap;
        transition: .2s linear;
        margin-right: 0.625rem;
        font-size: .9rem;
        font-weight: bold;
        &:hover {
            background-color: $color-red-darker;
            color: #FFF;
        }
    }
}
.card.offer {
    display: flex;
    flex-direction: column;

    .buttons {
        align-items: stretch;
        justify-content: center;
        .button {
            width: auto;
            box-shadow: 0 0 0.4375rem rgb(0 0 0 / 30%);
            min-height: 40px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .skew {
            transform: skew(calc( var(--skew) * -1));

            .skew-reverse {
                transform: skew(var(--skew));
            }
        }
    }
        

    .card-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
        .description {
            flex-grow: 1;
        }
    }
}

@media (max-width: 767px) {
    .container:not(.hotsite-navbar .container) {
        padding-left: 10px;
        padding-right: 10px;
    }
    .main-hero .hero-body:before {
        background: rgba(255,255,255,.8);
        transform: none;
    }
}
@media (min-width: 300px) and (max-width: 767px) {

    .search-form { 
        .field {
            display: flex;
            .icon {
                display: none;
            }

            > .input {
                border-radius: 0 !important;
            }

            .control .input {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
        .button {
            width: auto !important;
            height: 36px !important;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .input {
            font-size: .8rem !important;
            padding-left: 4px !important;


        }
    }

    .offers > .columns {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap;
        align-items: stretch;

        > .column {
            min-width: 50%;
            width: 50%;

            &:nth-child(odd) {
                padding-right: 5px;
            }
            &:nth-child(even) {
                padding-left: 5px;
            }
        }

        .card {
            .title {
                font-size: .9rem;
            }
            .subtitle {
                font-size: .8rem;
            }
            .media-content {
                overflow: hidden;
                max-height: auto;
            }
            .description {
                font-size: .8rem;
            }
            .offer-price {
                margin: 0 !important;
            }
            .buttons {
                margin-top: 0 !important;
                .button {
                    padding: 5px;
                    font-size: .8rem;
                    min-height: 30px;
                }
                .is-success {
                    flex: 0 0 35px;
                }
            }
        }
    }

}