@import "colors.scss";

@mixin bordered-logo-company {
    .image {
        border: 0.1875rem solid $color-gray-darker;
        border-color: var(--border-color, $color-gray-darker);
        border-radius: 0.3125rem;
        width: calc(100% - 0.625rem);
        margin-top: 0.625rem;
        figure img {
            transition: 0.3s ease-in-out;
            transition-property: transform;
            transform: translate(0.625rem, -0.625rem);
            border-radius: 0.3125rem;
            overflow: hidden;
            box-shadow: -0.125rem 0.125rem 0.4375rem rgba(0, 0, 0, 0.3);
        }
    }
}

@mixin text-content-reset {
    * {
        font-family: inherit;
        margin: 0;
        padding: 0;
        line-height: 1.6;
        font-size: 1rem;
        font-weight: normal;
    }

    strong {
        font-weight: bold;
    }
    span {
        display: inline;
    }
    a {
        color: $color-red-darker;
        font-weight: bold;
    }
    p {
        margin-bottom: 0.3125rem;
    }
}

@mixin social-links {
    .social-links {
        display: flex;
        .social-link {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            text-align: center;
            color: #fff;
            font-size: 1.5rem;
            display: block;
            margin-left: 0.3125rem;
            line-height: 2.8rem;
            i {
                margin-left: 0.0625rem;
            }
            &.instagram {
                background: #c32aa3;
            }
            &.linkedin {
                background: #0a66c2;
            }
            &.whatsapp {
                background: #25d366;
            }
            &.facebook {
                background: #3b5998;
            }
            &.twitter {
                background: #1da1f2;
            }
            &.youtube {
                background: $color-red;
            }
            &.blog {
                background: $color-orange;
            }
            &.link {
                background: $color-gray-darker;
            }
            &.site {
                background: $color-blue;
            }
        }
    }
}

@mixin card-action-buttons {
    .company-buttons {
        margin-top: 0.625rem;
        text-align: center;
        .company-button {
            display: inline-block;
            width: auto;
            margin: 0 0rem;
            min-width: 4.1rem;
            min-height: 2.1875rem;
            padding-left: 0.625rem;
            padding-right: 0.625rem;
            text-align: center;
            color: #fff;
            border-radius: 0.3125rem;
            transform: skew(-15deg);
            font-size: 1.5rem;
            position: relative;
            box-shadow: 0 0 0.4375rem rgba(0, 0, 0, 0.3);
            transition: 0.1s ease-in-out;
            transition-property: transform box-shadow;
            z-index: 2;
            &:hover {
                transform: skew(-15deg) scale(1.05);
                z-index: 3;
                box-shadow: 0 0 0.4375rem rgba(0, 0, 0, 0.5);
            }
            .company-button-inner {
                transform: skew(15deg);
            }

            span {
                font-size: 1rem;
            }
        }
        .branches {
            margin-left: auto;
            right: 0;
        }
    }
}

@mixin company-action-buttons {
    .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;
        max-width: 50rem;
        margin: auto;
        height: 4.0625rem;
        padding: 0 0.9375rem;

        .action-button {
            width: calc(25% - 0.625rem);
            height: 100%;
            display: block;
            padding-left: 0.625rem;
            padding-left: 0.625rem;
            margin-left: 0.3125rem;
            margin-right: 0.3125rem;
            box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.3);
            transition: 0.1s ease-out;
            border-radius: 0.3125rem;

            .action-button-inner {
                height: 100%;
            }
            .action-button-content {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
            }

            z-index: 1;
            &:hover {
                transform: scale(1.05) skewX(calc(-1 * var(--skew)));
                filter: brightness(110%);
                z-index: 2;
            }
        }
        .icon,
        .label {
            color: #fff;
        }
        .icon {
            flex: 0 0 2.8125rem;
            font-size: 1.5rem;
        }
        .label {
            font-weight: normal;
            font-size: 1rem;
            font-weight: bold;
            line-height: 1.2;
        }

        .skew {
            transform: skewX(calc(-1 * var(--skew)));

            .skew-reverse {
                transform: skewX(var(--skew));
            }
        }
    }

    @media screen and (max-width: 47.9375rem) {
        .action-buttons {
            max-width: 31.25rem;
            .action-button {
                width: calc(100% - 0.3125rem);
                margin: 0.125rem;
                .label {
                    display: none;
                }
                .icon {
                    flex: 0 0 100%;
                    font-size: 2rem;
                }
                .action-button-content {
                    justify-content: center;
                    margin-left: -0.4375rem;
                }
            }
        }
    }
}

@mixin secondary-navbar {
    
    .secondary-navbar {
        background: $color-red-dark;

        .nav-items {
            .nav-item {
                color: #fff;
                display: inline-block;
                padding: 0.9375rem 1.25rem;
                transition: 0.2s ease-out;
                width: auto;
                &:hover {
                    background: lighten($color-red-dark, 2%);
                }
            }

            @media (max-width: 34.375rem) {
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 4.375rem;
                    opacity: 50%;
                    height: 100%;
                    background: $gradient-eol-rtl;
                }
            }
        }

        .breadcrumb li {
            width: auto;
        }
    }
}
