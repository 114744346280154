// // old css
//.banner img{width:100%;height:auto}.text .content,.text .content *{background:0 0!important;line-height:0.9375rem}.about{margin:auto}.about .content,.about .content *{line-height:1.8rem;font-size:1rem;background:0 0!important}.about .content a{color:#c8010f}.action-buttons{max-width:50rem;margin:auto}.subcategories .subcategory .image{width:4.375rem;height:4.375rem;border-radius:50%;border:0.125rem solid #fff;padding:0.9375rem;display:block;margin:auto}.subcategories .subcategory .image img{width:100%;height:100%;display:block;margin:0 auto 0.625rem}.times p{margin-bottom:0.3125rem;line-height:1.5rem}.payment-methods img{height:1.875rem;width:auto;display:inline-block;margin:0.3125rem}.social a{font-size:2rem;width:2.1875rem;height:auto;display:inline-block;text-align:center;cursor:pointer;padding:0.3125rem}.social .facebook{color:#1877f2}.social .twitter{color:#1da1f2}.social .instagram{color:#c32aa3}.social .blog{color:#ff5722}.video-container{position:relative;padding-bottom:56.25%;padding-top:1.875rem;height:0;overflow:hidden}.video-container embed,.video-container iframe,.video-container object{position:absolute;top:0;left:0;width:100%;height:100%}#map{min-height:25rem}

@import "colors.scss";
@import "mixins";

@import "../../node_modules/swiper/swiper-bundle.css";
@import "../../node_modules/photoswipe/dist/photoswipe.css";
@import "./offers.scss";

/**
 * company colors
 */
html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
    padding-top: 5.03124976rem!important;
}

:root {
    --skew: 12deg;
}

.grow-adjust {
    display: flex;
    flex-direction: column;
    .payment-methods {
      flex-grow: 1;
    }
  }

.action-buttons {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 50rem;
    margin: auto;
    margin-top: -2.0938rem;
    height: 4.0625rem;
    padding: 0 0.9375rem;

    .action-button {
        width: calc( 25% - 0.625rem );
        height: 100%;
        display: block;
        padding-left: 0.625rem;
        padding-left: 0.625rem;
        margin-left: 0.3125rem;
        margin-right: 0.3125rem;
        box-shadow: 0 0 0.625rem rgba(0,0,0,0.3);
        transition: .1s ease-out;
        border-radius: 0.3125rem;

        .action-button-inner {
            height: 100%;
        }
        .action-button-content {
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
        }

        z-index: 1;
        &:hover {
            transform: scale(1.05) skewX(calc(-1 * var(--skew)) );
            filter: brightness(110%);
            z-index: 2;
        }

    }
    .icon, .label {
        color: #FFF;
    }
    .icon {
        flex: 0 0 2.8125rem;
        font-size: 1.5rem;
    }
    .label {
        font-weight: normal;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.2;
    }

    .skew {
        transform: skewX(calc(-1 * var(--skew)) );

        .skew-reverse {
            transform: skewX(var(--skew));
        }
    }
}

.brand {
    @include bordered-logo-company;
}

.sidebar {
   .social {
    @include social-links;
   }
}
.about {
    .description {
        @include text-content-reset;
    }
}

.content-mobile {
    .description {
        font-size: .9rem;
        .image {
            max-width: 9.375rem;
            float: left;
            margin-right: 1.25rem;
            margin-bottom: 0.625rem;
        }

        ~ .read-more {
            display: none;
        }
        
        &.excerpt {
            max-height: 14.6875rem;
            overflow: hidden;
            position: relative;
        }
        &.excerpt ~ .read-more {
            display: block;
            text-align: center;
            background: $gradient-white;
            padding: 5rem 0.3125rem 0.9375rem 0.3125rem;
            margin-bottom: 1.25rem;
            margin-top: -5.625rem;
            z-index: 10;
            position: relative;
            color: var(--company-primary-color);
            font-weight: bold;
        }
    }
    
    .title {
        font-size: 1.2rem;
    }
}

.gallery {
    .carousel {
        .item {

            margin: 0.625rem;
            .skew {
                transform: skewX(calc(-1 * var(--skew)));
                overflow: hidden;
                .skew-reverse {
                    transform: skewX( var(--skew) );
                }
                box-shadow: 0 0 0.625rem rgba(0,0,0, 0.5);
                
            }
            .img {
                width: auto;
                padding-top: 90%;
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: -20%;
                margin-left: -20%;
            }
        }
    }
}

.section-header {
    text-align: center;

    h1.title, h2.title {
        display: inline-block;
        background: var(--company-primary-color);
        color: var(--company-primary-contrast-color);
        padding: 0.3125rem 3.75rem;
        text-transform: uppercase;
        position: relative;
        font-size: 1.8rem;
        border-radius: 0.3125rem;

        &:after {
            border-radius: 0.3125rem;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            border: 0.0625rem solid var(--company-primary-color);
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(-0.4375rem, 0.4375rem);
            z-index: -1;
        }
    }
}

.services {
    margin-top: 1.25rem;

    .main-services {
        margin: 1.875rem auto;
        max-width: 50rem;
        .is-flex {
            flex-wrap: wrap;
        }
        .service {
            .image {
                max-width: 3.125rem;
                margin: auto;
                img {
                    filter: brightness(20%);
                }
            }
            .title {
                color: var(--company-primary-color);
            }
            text-align: center;
        }
    }

    .additional-services {
        .column {
            margin: 0;
            padding: 0;
        }
        .is-flex {
            flex-wrap: wrap;
        }
        .item {
            padding: 0.125rem 0.5rem;
            margin: 0 0.125rem 0.3125rem 0.125rem;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #000000;

            &:hover {
                background: var(--company-primary-color);
                color: var(--company-primary-contrast-color);
            }
        }

        .read-more {
            display: none;
        }
    }
}

.offers {
    margin-top: 1.875rem;
    padding: 0 1.25rem;
    .offers-list {
        margin-top: 1.875rem;
    }
    .col {
        padding: 0.625rem;
        height: 100%;
    }
    .card {
        border: 0.0625rem solid var(--company-primary-contrast-color);
        box-shadow: 0 0 0.625rem rgba(0,0,0,0.3);
    }
    .offer {
        height: 100%;
        .offer-title {
            text-align: center;
            font-weight: bold;
        }
        .price {
            margin-top: 0.125rem;
            font-weight: bold;
            text-align: center;
        }
        .card {
            height: 100%;
        }
        .card-content {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            min-height: 6.25rem;
            display: flex;
            flex-direction: column;
            align-items: space-between;
            justify-content: space-between;
        }
        .button {
            font-size: 1rem;
            padding: 0;
        }
    }
}

.payment-methods {
    margin-bottom: 1.5625rem;
}
.payment-methods-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 1.25rem 1.25rem 1.25rem;
    .payment-method {
        padding: 0.625rem;
        img {
            display: block;
            height: 2rem;
            width: auto
        }
    }
}

    .social {
        @include social-links;

        padding: 0 0.625rem;

        display: flex;
        justify-content: center;

        .social-link {
            width: 3.125rem !important;
            height: 3.125rem !important;

            i {
                font-size: 1.8rem;
                margin-top: 0.75rem;
            }
        }
    }

.video {
    padding-bottom: 5rem;
    .video-container {
        width: 100%;
        max-width: 37.5rem;
        margin: auto;
        padding-top: 56.25%;
        position: relative;
        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.contact {
    .map {
        height: 100%;
        iframe {
            height: 100%;
        }
    }

    .form {
        max-width: 25rem;

        .h-captcha {
            text-align: center;
        }
        .button {
            background-color: $color-red-darker;
            color: #FFF;
            width: 100%;
        }

        .control {
            input, textarea {
                background-color: transparent;
                color: var(--company-primary-contrast-color);
                box-shadow: none !important;
                padding: 0.625rem 0.75rem;
                &::placeholder {
                    color: var(--company-primary-contrast-color);
                }
                &:focus, &:active {
                    border-color: initial;
                }
            }
        }

        .title {
            color: var(--company-primary-contrast-color);
        }
    }
}

.modal.phones {
    a {
        display: block;
        font-size: 1.4rem;
        margin-bottom: 0.75rem;
        text-align: center;

        i {
            color: var(--company-primary-color);
            margin-right: 0.9375rem;
        }
    }
}

@media screen and (max-width: 47.9375rem ) {
    .action-buttons {
        max-width: 31.25rem;
        .action-button {
            width: calc(100% - 0.3125rem);
            margin: 0.125rem;
            .label {
                display: none;
            }
            .icon {
                flex: 0 0 100%;
                font-size: 2rem;
            }
            .action-button-content {
                justify-content: center;
                margin-left: -0.4375rem;
            }
        }
    }

    .contact {
        .map iframe {
            min-height: 18.75rem;
        }
        .form {
            margin: auto;
            [type="submit"] {
                width: 100%;
            }
        }
    }


    .services .main-services {
        margin: 2rem 0;
    }
    .services > .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .additional-services {
        .item {
            margin-bottom: 0.2125rem;
            text-align: center;
            font-size: .8rem;
            padding: 0 !important;
        }
        .excerpt {
            max-height: 13.4375rem;
            overflow: hidden;
            position: relative;
        }
        .excerpt ~ .read-more {
            display: block;
            text-align: center;
            background: $gradient-white;
            padding: 5rem 0.3125rem 0.9375rem 0.3125rem;
            margin-bottom: 1.25rem;
            margin-top: -5.625rem;
            z-index: 10;
            position: relative;
            color: var(--company-primary-color);
            font-weight: bold;
        }
        > .px-4 {
            padding-left: 5px;
        }
    }

    .container.p-5 {
        padding: .8rem !important;
    }

    .company > .columns {
        display: flex;
        flex-direction: column;
        > .column:nth-child(1) {
            order: 2;
        }
        > .column:nth-child(2) {
            order: 1;
        }
    }

    .offers {
        padding: 0;
        .offer {
            .card {
                padding-bottom: 1.25rem;
            }
            .title {
                margin-top: 1.25rem;
            }
        }
    }

    .sidebar.card {
        .card-content {
            padding-left: 0.25rem;
            padding-bottom: 1.25rem;
            margin: auto;
        }
    }
}

@media (max-width: 1215px) and (min-width: 767px) {
    .action-buttons {
        .action-button {
            width: 100%;
        }
    }
}

@media screen and (max-width: 31.25rem ) {
    .payment-methods-list {
        padding: 0;
        .payment-method {
            img {
                height: 1.875rem;
            }
        }
    }
    .content-mobile .description .image {
        max-width: 6.25rem;
    }
    .action-buttons {
        height: 2.8125rem;
        margin-top: -1.4063rem;
        .action-button {
            .icon {
                font-size: 1.3rem;
            }
        }
    }
}